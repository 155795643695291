<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item elfrom_item" label="结算时间">
				<div class="block">
				<el-date-picker
					v-model="creat_time_arr1"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
                <el-button type="primary" @click="data_export_open">导出</el-button>
			</el-form-item>
		</el-form>
		<div class="total_info item">
			<span>共:{{list.total}}条</span>
			<span>总运费:{{list.freight_total_all}}元</span>
            <span>收款人运费总计{{list.give_truck_owner_total_all}}元</span>
            <span>司机运费总计{{list.give_driver_total_all}}元</span>
			<span>总服务费{{list.service_charge_all}}元</span>
		</div>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading" @selection-change="tords_list_choose" >
				<!-- <el-table-column type="selection" width="39px"></el-table-column> -->
				<el-table-column label="公司名称" >
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="总运费"  width="200px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.freight_total}}</div>
					</template>
				</el-table-column>
				<el-table-column label="收款人(元)" width="200px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.give_truck_owner_total}}</div>
					</template>
				</el-table-column>
				<el-table-column label="司机(元)" width="200px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.give_driver_total}}</div>
					</template>
				</el-table-column>
				<el-table-column label="总服务费" width="200px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.service_charge}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单数" width="200px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.num}}</div>
					
					</template>
				</el-table-column>
				<!-- <el-table-column label="操作网点" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">姓名:{{scope.row.outlets_info.name?scope.row.outlets_info.name:'无'}}</div>
						<div class="tab_line_item">电话:{{scope.row.outlets_info.tel?scope.row.outlets_info.tel:'无'}}</div>
						<div class="tab_line_item">网点:{{scope.row.outlets_info.outlets_name?scope.row.outlets_info.outlets_name:'无'}}</div>
					</template>
				</el-table-column> -->
				<el-table-column label="收款人比例(%)" width="200px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.truck_owner_mode}}</div>
					</template>
				</el-table-column>
				<el-table-column label="司机比例(%)" width="200px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.driver_mode}}</div>
					</template>
				</el-table-column>

			</el-table>
		</div>
		<el-backtop
		target=".page"
		:bottom="100"
		:visibility-height="50"
		></el-backtop>
		<!-- 分页 -->
		<!-- <el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 50, 100, 200]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination> -->




	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import serRelExport from '../transit_real_time/tord/act/ser_rel_export.vue'
	export default {
		components:{
			serRelExport,
		},
		data() {
			return {
				date_value:'',
				invoice_time:'',
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				total:'',//总计费用
				//搜索界面是否打开
				ser_form_is_show:0,

				//搜索条件
				form: {				

					start_time:'', //结算开始时间
                    end_time:'',//结算结束时间


				},
				creat_time_arr1:'',
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
					freight_total_all:'',//所有公司运费总计
                    give_driver_total_all:'', //所有公司司机运费总计
                    give_truck_owner_total_all:'',//所有公司收款人运费总计
                    service_charge_all:'',//所有公司总服务费总计
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				
				//选中的运单
				tords_choosed:[],

			}
		},
		created() {
            const end = new Date();
            const start = new Date();
            // start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
            this.creat_time_arr1 = [start.getTime() - 3600 * 1000 * 24 * 30,end.getTime()]
            this.form.start_time = parseInt(this.creat_time_arr1[0] / 1000);
            this.form.end_time= parseInt(this.creat_time_arr1[1] / 1000);
			//读取货源
			this.get_page_data()
		},
		computed:{
			...mapState(['logink_aim_list','user_info'])
		},
		methods: {
            //导出
            data_export_open(){
				var shunxu=[
					{key:"name",cname:"公司名称"},
					{key:"num",cname:"运单个数"},
					{key:"freight_total",cname:"总运费"},
					{key:"give_truck_owner_total",cname:"支付给收款人总运费"},
					{key:"give_driver_total",cname:"支付给司机总运费"},
                    {key:"service_charge",cname:"总服务费"},
					{key:"truck_owner_mode",cname:"支付给收款人比例单位：%"},
					{key:"driver_mode",cname:"支付给司机比例单位：%"},
				],str="";
				for(var a in shunxu){
					str+=shunxu[a].cname+",";
				}
				str+="\n";
				for(var i=0;i<this.list.data.length;i++){
					for(var a in shunxu){
						str+=`${this.list.data[i][shunxu[a].key]}\t,`;
					}
					str+='\r\n';
				}

				str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
				//生成下载链接
				let link = document.createElement('a');
				link.href = str;

				//对下载的文件命名
				link.download = "统计数据.csv";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
            },
			//勾选
			tords_list_choose(event){
				//置入
				this.tords_choosed=event
			},
			

			
			//凭证相关操作
			voucher_show(item){//显示凭证
				this.voucher.is_show++;
				this.voucher.weight={
					cargo_weight_start:item.cargo_weight_start,
					cargo_weight_arrived:item.cargo_weight_arrived,
					cargo_weight:item.cargo_weight
				}
				this.voucher.truck_tord_num=item.truck_tord_num
			},
			tord_delete_close(){
				this.set_act_delete.show_msg=''
				this.set_act_delete.truck_tord_num = ''
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1
				if (this.creat_time_arr1) {
					this.form.start_time = parseInt(this.creat_time_arr1[0] / 1000);
					this.form.end_time= parseInt(this.creat_time_arr1[1] / 1000);
				} else {
					this.form.start_time = "";
					this.form.end_time = "";
				}
				
				//读取
				this.get_page_data()
			},
			
			//清空查询条件
			ser_para_clear(){
				this.form={
                    start_time:'',
                    end_time:''
				}
				//读取
				this.get_page_data()
				
			},


			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},
			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					// return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'see_act_count',
						is_get_shipper_info:1,
						is_get_shipper_company_info:1,
						is_get_truck_owner_info:1,
						is_get_driver_info:1,
						is_get_truck_info:1,
						is_get_tord_outlets:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{
						if(data.code ==0){
							//加载完毕
							this.list.loading=false

							//总数
							this.list.total=data.msg.max

                            //所有公司运费总计
                            this.list.freight_total_all = data.msg.freight_total_all

							//所有公司司机运费总计
							this.list.give_driver_total_all = data.msg.give_driver_total_all

							//总服务费
							this.list.service_charge_all = data.msg.service_charge_all

                            //所有公司收款人运费总计
                            this.list.give_truck_owner_total_all = data.msg.give_truck_owner_total_all

							//总计费用
							// this.total = (Number(data.msg.total.total_freight) + Number(data.msg.total.total_service)).toFixed(2)
							//预处理
							// for(let item of data.msg.list){
							// 	item.cargo_weight = Number(item.cargo_weight).toFixed(2)
							// 	//成交时间
							// 	item.creat_time_text=this.$my.other.totime(item.creat_time);
								
							// 	//发车时间
							// 	if(item.start_time>0){//已发车
							// 		item.start_time_text=this.$my.other.totime(item.start_time);
							// 	}else item.start_time_text="未发车";
								
							// 	//到货时间
							// 	if(item.arrived_time>0){//已到货
							// 		item.arrived_time_text=this.$my.other.totime(item.arrived_time);
							// 	}else item.arrived_time_text="未到货";

							// 	//装货联系人
							// 	if(item.case_link_man){
							// 		item.case_link_man_text=item.case_link_man;
							// 	}else item.case_link_man_text="无";

							// 	//装货联系电话
							// 	if(item.case_link_tel){
							// 		item.case_link_tel_text=item.case_link_tel;
							// 	}else item.case_link_tel_text="无";

							// 	//卸货联系人
							// 	if(item.aim_link_man){
							// 		item.aim_link_man_text=item.aim_link_man;
							// 	}else item.aim_link_man_text="无";

							// 	//卸货联系电话
							// 	if(item.aim_link_tel){
							// 		item.aim_link_tel_text=item.aim_link_tel;
							// 	}else item.aim_link_tel_text="无";

							// 	/* 装货地其他数据(json) */
							// 	item.case_other_obj=JSON.parse(item.case_other);

							// 	//装货时间
							// 	if(!item.case_other_obj.case_time_start && item.case_other_obj.case_time_end){//只有止点

							// 		item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_end)+"以前";

							// 	}else if(item.case_other_obj.case_time_start && !item.case_other_obj.case_time_end){//只有起点

							// 		item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_start)+"以后";

							// 	}else if(item.case_other_obj.case_time_start && item.case_other_obj.case_time_end){//都有

							// 		item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_start)+" ~ "+this.$my.other.todate(item.case_other_obj.case_time_end);

							// 	}else if(!item.case_other_obj.case_time_start && !item.case_other_obj.case_time_end){//都没有

							// 		item.case_other_obj.case_date="未设置";
							// 	}

							// 	//发车时间
							// 	if(!item.case_other_obj.start_time_1 && item.case_other_obj.start_time_2){//只有止点

							// 		item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_2)+"以前";

							// 	}else if(item.case_other_obj.start_time_1 && !item.case_other_obj.start_time_2){//只有起点

							// 		item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_1)+"以后";

							// 	}else if(item.case_other_obj.start_time_1 && item.case_other_obj.start_time_2){//都有

							// 		item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_1)+" ~ "+this.$my.other.todate(item.case_other_obj.start_time_2);

							// 	}else if(!item.case_other_obj.start_time_1 && !item.case_other_obj.start_time_2){//都没有
									
							// 		item.case_other_obj.start_time="未设置";
							// 	}

							// 	//发车时间是否强制校验
							// 	switch(item.case_other_obj.start_time_is_check){
							// 		case '1':item.case_other_obj.start_time_is_check_text="(强制)";break;
							// 		case '2':item.case_other_obj.start_time_is_check_text="(不强制)";break;
							// 	}

							// 	//异常运单是否验证
							// 	switch(item.check_act_status){
							// 		case '1':item.check_act_status_text="已验证";break;
							// 		case '2':item.check_act_status_text="未验证";break;
							// 		case '2':item.check_act_status_text="验证失败";break;
							// 	}
							// 	if(item.case_other_obj.start_time=="未设置"){//如果未设置发车时间,则不存在强制不强制
							// 		item.case_other_obj.start_time_is_check_text=""
							// 	}

							// 	/* 卸货地其他数据(json) */
							// 	item.aim_other_obj=JSON.parse(item.aim_other);

							// 	//卸货时间
							// 	if(!item.aim_other_obj.aim_time_start && item.aim_other_obj.aim_time_end){//只有止点

							// 		item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_end)+"以前";

							// 	}else if(item.aim_other_obj.aim_time_start && !item.aim_other_obj.aim_time_end){//只有起点

							// 		item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_start)+"以后";

							// 	}else if(item.aim_other_obj.aim_time_start && item.aim_other_obj.aim_time_end){//都有

							// 		item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_start)+" ~ "+this.$my.other.todate(item.aim_other_obj.aim_time_end);

							// 	}else if(!item.aim_other_obj.aim_time_start && !item.aim_other_obj.aim_time_end){//都没有
									
							// 		item.aim_other_obj.aim_date="未设置";
							// 	}

							// 	//运单状态
							// 	switch(item.status){
							// 		case '1':item.status_text="待发车";break;
							// 		case '2':item.status_text="运输中";break;
							// 		case '3':item.status_text="已到货";break;
							// 	}

							// 	//锁定状态
							// 	switch(item.lock_status){
							// 		case '1':item.lock_status_text="已锁定";break;
							// 		case '2':item.lock_status_text="未锁定";break;
							// 	}
								
							// 	//上报状态
							// 	switch(item.tax_upl_status){
							// 		case '1':item.tax_upl_status_text="未同步";break;
							// 		case '2':item.tax_upl_status_text="已同步尚未第一次上传";break;
							// 		case '3':item.tax_upl_status_text="第一次已上传";break;
							// 		case '4':item.tax_upl_status_text="一次失败";break;
							// 		case '5':item.tax_upl_status_text="二次已上传";break;
							// 		case '6':item.tax_upl_status_text="二次失败";break;
							// 		case '7':item.tax_upl_status_text="三次已上传";break;
							// 		case '8':item.tax_upl_status_text="三次失败";break;
							// 		case '9':item.tax_upl_status_text="其他";break;
							// 	}

							// 	//结算方式
							// 	switch(item.settlement_type){
							// 		case '1':item.settlement_type_text="按吨数";break;
							// 		case '2':item.settlement_type_text="按方数";break;
							// 		case '3':item.settlement_type_text="按趟";break;
							// 	}
								
							// 	//是否回程
							// 	switch(item.is_need_return){
							// 		case '1':item.is_need_return_text="需要回程";break;
							// 		case '2':item.is_need_return_text="不需要回程";break;
							// 	}

							// 	//匹配方式
							// 	switch(item.match_way){
							// 		case '1':item.match_way_text="收款人抢单";break;
							// 		case '2':item.match_way_text="指定后收款人接单";break;
							// 		case '3':item.match_way_text="收款人竞价中标";break;
							// 		case '4':item.match_way_text="收款人扫码接单";break;
							// 		case '5':item.match_way_text="网点线下匹配";break;
							// 		case '6':item.match_way_text="合作方匹配";break;
							// 		case '7':item.match_way_text="后台匹配";break;
							// 		case '8':item.match_way_text="后台添加";break;
							// 	}

							// 	//应收总额
							// 	if(item.cost_info.freight_total){
							// 		item.cost_info.freight_total_text=item.cost_info.freight_total+" 元";
							// 	}else item.cost_info.freight_total_text="未设置";

							// 	//应付总额
							// 	if(item.cost_info.give_carrier_total){
							// 		item.cost_info.give_carrier_total_text=item.cost_info.give_carrier_total+" 元";
							// 	}else item.cost_info.give_carrier_total_text="未设置";

							// 	//已付总额
							// 	item.cost_info.has_payed_total_text=item.cost_info.has_payed_total+" 元"

							// 	//运费支付状态
							// 	switch(item.payed_status){
							// 		case '1':item.payed_status_text="未支付";break;
							// 		case '2':item.payed_status_text="部分支付";break;
							// 		case '3':item.payed_status_text="支付完毕";break;
							// 	}

							// 	//运费结算状态
							// 	switch(item.settled_status){
							// 		case '1':item.settled_status_text="未结算";break;
							// 		case '2':item.settled_status_text="部分结算";break;
							// 		case '3':item.settled_status_text="结算完毕";break;
							// 	}

							// 	//货车类型
							// 	item.truck_info.truck_type_name_text=`
							// 		${item.truck_info.truck_type_name?'/'+item.truck_info.truck_type_name+'/':''}
							// 		${item.truck_info.truck_type_name2?item.truck_info.truck_type_name2:''}
							// 	`;

							// 	//上报
							// 	switch(item.upl_status){
							// 		case '1':item.upl_text="未上报";break;
							// 		case '2':item.upl_text="运单上报成功";break;
							// 		case '3':item.upl_text="运单上报失败";break;
							// 		case '4':item.upl_text="流水上报成功";break;
							// 		case '5':item.upl_text="流水上报失败";break;
							// 	}
							// 	if(item.upl_aim)item.upl_text+=`(${this.logink_aim_list[item.upl_aim]})`
							// }

							//渲染
							this.list.data=data.msg.list
						}else{
							this.$my.other.msg({
								str:data.error_info,
								type:'info'
							});
							this.list.loading=false
							this.list.data=[]
						}

					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:1000px;
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
		.total_info {
		text-align: right;
		font-size: 12px;
		margin-bottom: 24px;
		margin-right: 1px;
		margin-top: 10px;
		color: #606266;
		
		span {
			margin-left: 20px;
		}
	}
	.elfrom_item{
		width:auto;
		margin-right:10px;
	}
	/deep/.el-button--text{
		color: #409eff !important;
	}
	.btn_delete{
		color:red !important
	}
</style>